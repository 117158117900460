import React from "react";
import { graphql } from "gatsby";

// Component imports
import PainLoggerLayout from "../../components/pain_logger/PainLoggerLayout";
import AppHeroSection from "../../components/app_pages/AppHeroSection";
import AppSeparator from "../../components/app_pages/AppSeparator";
import AppFeaturesHeader from "../../components/app_pages/AppFeaturesHeader";
import AppFeaturesSection from "../../components/app_pages/AppFeaturesSection";
import AppFeature from "../../components/app_pages/AppFeature";
import AppFAQSection from "../../components/app_pages/AppFAQSection";
import AppFAQItem from "../../components/app_pages/AppFAQItem";
import SEO from "../../components/common/seo";

// Image imports
import MosaicImage from "../../assets/img/pain_logger/iphone_ipad_mosaic.png";
import AppIcon from "../../assets/img/pain_logger/PainLogger_Icon_Final_300x300.png";
//import HeroBackgroundImage from "../assets/img/painlogger/footer_lodyas.png";

// CSS imports
import "../../assets/sass/app_page.scss";

export default ({ data }) => {
  //<a href="https://itunes.apple.com/us/app/pain-logger/id558604942?mt=8" style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2012-09-12&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;"></a>
  return (
    <PainLoggerLayout>
      <SEO title="Pain Logger Home" keywords={[`pain`, `painlogger`]} />
      <AppHeroSection
        sectionClass="pain_logger"
        useDarkMode={true}
        heroTitle="Pain Logger"
        appIcon={AppIcon}
        heroText="Your go-to app to track your day-to-day pain levels. A valuable tool in chronic pain management"
        image={MosaicImage}
        items={[
          "Schedule reminders throughout the day",
          "Email and export your results",
          "Simple and easy to understand pain level picker"
        ]}
        actionURL="https://itunes.apple.com/us/app/pain-logger/id558604942?mt=8"
      />
      <AppSeparator
        actionText="Download Now - Track Your Pain"
        actionLead="Available on the app store for free. For iPhone and iPad."
        actionURL="https://itunes.apple.com/us/app/pain-logger/id558604942?mt=8"
      />
      <AppFeaturesHeader
        sectionClass="pain_logger"
        title="Features"
        lead="Pain Logger has the features you need to track pain levels."
      />
      <AppFeaturesSection>
        <AppFeature
          faIcon="fas fa-bolt"
          title="Multiple Pain Areas"
          description="Track multiple pain areas"
        />
        <AppFeature
          faIcon="far fa-clock"
          title="Customizable Alarms"
          description="Set an alarm for each pain area."
        />
        <AppFeature
          faIcon="fas fa-chart-line"
          title="Graphs"
          description="Graph your pain areas over time to see trends and gain insights into what works and doesn't."
        />
        <AppFeature
          faIcon="fas fa-print"
          title="Print"
          description="Print your graphs or data, and send them to your care provider."
        />
        <AppFeature
          faIcon="fas fa-globe"
          title="Location Alarms"
          description="You can set alarms based on your location, aka geo-fencing."
        />
        <AppFeature
          faIcon="fas fa-cloud"
          title="Sync"
          description="Sync between multiple devices through iCloud."
        />
      </AppFeaturesSection>
      <AppFAQSection
        lead="Find the answers to your Pain Logger questions and learn best practices from other users."
        morePage="pain_logger/pain_logger_faq"
      >
        {data.top_faq_items.edges.map(({ node }) => {
          return (
            <AppFAQItem
              question={node.frontmatter.title}
              answer={node.excerpt}
            />
          );
        })}
      </AppFAQSection>
    </PainLoggerLayout>
  );
};

export const query = graphql`
  {
    top_faq_items: allMarkdownRemark(
      limit: 3
      filter: { fileAbsolutePath: { glob: "**/md/pain_logger/faq_*.md" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
          }
          id
          excerpt
        }
      }
    }
  }
`;
