import React from "react";

// Component imports

export default class AppFAQItem extends React.Component {
  render() {
    return (
      <div className="col-md-4 mb-4">
        <p className="text-left lead">
          <span className="font-tss font-weight-normal">
            {this.props.question}
          </span>
        </p>
        <p className="font-weight-light">{this.props.answer}</p>
      </div>
    );
  }
}
